#DraggableQuestionCard {
  background: #fafafa;
  width: 100%;
  height: 140px;
  min-height: 140px;
  margin-bottom: 12px;
  padding: 16px;
  position: relative;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  border-radius: 5px;
}

#CustomDraggableQuestionCard {
  background: #fafafa;
  width: 100%;
  width: 150px;
  min-height: 140px;
  margin-bottom: 12px;
}

.draggableCard,
.disabledDraggableCard {
  box-shadow: 2px 2px 5px rgba(150, 150, 150, 0.6) !important;
  transition: "all 0.2s cubic-bezier(.25,.8,.25,1)";
  margin-bottom: 8px !important;
}

.disabledDraggableCard {
  background: #f5f5f5;
  opacity: 50%;
}

#LockSortIcon {
  font-size: 16px;
}
