@page {
  margin: 0;
  size: letter !important;
}

.documentGen {
  margin: 0;
}

.documentGen .sheet {
  margin: 0;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  page-break-after: always;
  -webkit-print-color-adjust: exact;
}

.documentInput {
  width: 100%;
  height: 100%;
  min-height: 20px;
  font-size: 14;
  border: 1px solid black;
  background-color: rgba(230, 230, 255, 0.8);
}

.documentSelect {
  text-align: center;
  min-height: 20px;
  padding: 2px;
  border: 1px solid black;
  background-color: rgba(230, 230, 255, 0.8);
}

.documentRadio,
.documentCheckbox {
  vertical-align: middle;
}

/** Paper sizes **/
.documentGen.A3 .sheet {
  width: 297mm;
  height: 419mm;
}
.documentGen.A3 .landscape .sheet {
  width: 420mm;
  height: 296mm;
}
.documentGen.A4 .sheet {
  width: 210mm;
  height: 296mm;
}
.documentGen.A4 .landscape .sheet {
  width: 297mm;
  height: 209mm;
}
.documentGen.A5 .sheet {
  width: 148mm;
  height: 209mm;
}
.documentGen .A5 .landscape .sheet {
  width: 210mm;
  height: 147mm;
}
.documentGen .letter .sheet {
  width: 216mm;
  height: 279mm;
}
.documentGen .letter .landscape .sheet {
  width: 280mm;
  height: 215mm;
}
.documentGen .legal .sheet {
  width: 216mm;
  height: 356mm;
}
.documentGen .legal .landscape .sheet {
  width: 357mm;
  height: 215mm;
}

/** Padding area **/
.documentGen .sheet.padding-10mm {
  padding: 10mm;
}
.documentGen .sheet.padding-15mm {
  padding: 15mm;
}
.documentGen .sheet.padding-20mm {
  padding: 20mm;
}
.documentGen .sheet.padding-25mm {
  padding: 25mm;
}

/** For screen preview **/
@media screen {
  .documentGen {
    background: #e0e0e0;
    margin: 0 auto;
  }
  .documentGen .sheet {
    background: white;
    box-shadow: 0 0.5mm 2mm rgba(0, 0, 0, 0.3);
    margin: 0 auto;
  }
}

/** Fix for Chrome issue #273306 **/
@media print {
  .documentGen.A3 .landscape {
    width: 420mm;
  }
  .documentGen.A3,
  .documentGen.A4 .landscape {
    width: 297mm;
  }
  .documentGen.A4,
  .documentGen.A5 .landscape {
    width: 210mm;
  }
  .documentGen.A5 {
    width: 148mm;
  }
  .documentGen .letter,
  .documentGen .legal {
    width: 216mm;
  }
  .documentGen .letter.landscape {
    width: 280mm;
  }
  .documentGen .legal.landscape {
    width: 357mm;
  }
}

.documentGen .title {
}

.documentGen .sheet .content table {
  text-align: justify;
  margin-top: 0px;
  margin-bottom: 0px;
}

.documentGen .sheet .content h6 {
  margin-top: 10px;
}

.documentGen .sheet .content table thead th {
  color: #ffffff;
}

.documentGen .sheet .content table tbody tr td {
  word-break: "break-all";
}

.documentGen .sheet .content {
  overflow: hidden;
  position: absolute;
  margin-right: 15mm;
  margin-bottom: 22mm;
  text-align: justify;
}

.documentGen .sheet .content table.smallTable th {
  padding-top: 0;
  padding-bottom: 0;
}

.documentGen .sheet .content table.smallTable td {
  padding-top: 0;
  padding-bottom: 0;
}
