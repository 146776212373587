.layout {
  min-height: 100vh;
}

.layout-sider {
  position: sticky !important;
  top: 0 !important;
  left: 0 !important;
  width: 256px !important;
  min-width: 256px !important;
  max-width: 256px !important;
  height: 100vh !important;
  min-height: 100vh !important;
  max-height: 100vh !important;
}

.layout-sider-collapsed {
  position: sticky !important;
  top: 0 !important;
  left: 0 !important;
  /* width: 100% !important; */
  width: 60px !important;
  height: 100vh !important;
  min-height: 100vh !important;
  max-height: 100vh !important;
}

.sider-brand {
  height: 100px;
  margin: 16px;
}

.sider-brand-small {
  height: 40px;
  margin: 8px;
}

.layout-content {
  margin: 16px;
  background: #eaeaea !important;
}

.layout-content-mobile {
  margin: 8px;
}
