@page {
  margin: 0;
  size: letter !important;
}

.documentView {
  padding: 0;
  padding-top: 20px;
  margin: 0;
  overflow-x: scroll;
}

.documentView .sheet {
  margin: 0;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  page-break-after: always;
  -webkit-print-color-adjust: exact;
}

#selectedAssignment {
  box-shadow: inset 0 0 0 2px rgba(18, 64, 190, 0.6);
  background-color: rgba(210, 210, 255, 0.4);
}
#savedAssignment {
  box-shadow: inset 0 0 0 2px rgba(21, 190, 18, 0.6);
  background-color: rgba(210, 255, 211, 0.4);
}
#unsavedAssignment {
  box-shadow: inset 0 0 0 2px rgba(128, 110, 110, 0.509);
  background-color: rgba(213, 209, 208, 0.226);
}
#missingAssignment {
  box-shadow: inset 0 0 0 2px rgba(255, 0, 0, 0.949);
  background-color: rgba(255, 0, 0, 0.351);
}
#mismatchTypes {
  box-shadow: inset 0 0 0 2px rgba(255, 255, 0, 0.958);
  background-color: rgba(243, 246, 100, 0.885);
}
#answeredButton {
  box-shadow: none 0 0 0 3px rgba(0, 0, 0, 0.958);
  background-color: rgba(47, 40, 40, 0.5);
  color: white;
  font-weight: 800;
}
.documentInput {
  width: 100%;
  height: 100%;
  min-height: 20px;
  font-size: 14;
  border: 1px solid black;
  background-color: rgba(230, 230, 255, 0.8);
}

.documentSelect {
  text-align: center;
  min-height: 20px;
  padding: 2px;
  border: 1px solid black;
  background-color: rgba(230, 230, 255, 0.8);
}

.documentRadio,
.documentCheckbox {
  vertical-align: middle;
  max-height: 18px;
}

/** Paper sizes **/
.documentView.A3 .sheet {
  width: 297mm;
  height: 419mm;
}
.documentView.A3 .landscape .sheet {
  width: 420mm;
  height: 296mm;
}
.documentView.A4 .sheet {
  width: 210mm;
  height: 296mm;
}
.documentView.A4 .landscape .sheet {
  width: 297mm;
  height: 209mm;
}
.documentView.A5 .sheet {
  width: 148mm;
  height: 209mm;
}
.documentView .A5 .landscape .sheet {
  width: 210mm;
  height: 147mm;
}
.documentView .letter .sheet {
  width: 216mm;
  height: 279mm;
}
.documentView .letter .landscape .sheet {
  width: 280mm;
  height: 215mm;
}
.documentView .legal .sheet {
  width: 216mm;
  height: 356mm;
}
.documentView .legal .landscape .sheet {
  width: 357mm;
  height: 215mm;
}

/** Padding area **/
.documentView .sheet.padding-10mm {
  padding: 10mm;
}
.documentView .sheet.padding-15mm {
  padding: 15mm;
}
.documentView .sheet.padding-20mm {
  padding: 20mm;
}
.documentView .sheet.padding-25mm {
  padding: 25mm;
}

/** For screen preview **/
@media screen {
  .documentView {
    background: #e0e0e0;
    margin: 0 auto;
  }
  .documentView .sheet {
    background: white;
    box-shadow: 0 0.5mm 2mm rgba(0, 0, 0, 0.3);
    margin: 0 auto;
  }
}

/** Fix for Chrome issue #273306 **/
@media print {
  .documentView.A3 .landscape {
    width: 420mm;
  }
  .documentView.A3,
  .documentView.A4 .landscape {
    width: 297mm;
  }
  .documentView.A4,
  .documentView.A5 .landscape {
    width: 210mm;
  }
  .documentView.A5 {
    width: 148mm;
  }
  .documentView .letter,
  .documentView .legal {
    width: 216mm;
  }
  .documentView .letter.landscape {
    width: 280mm;
  }
  .documentView .legal.landscape {
    width: 357mm;
  }
}

.documentView .title {
}

.documentView .sheet .content table {
  text-align: justify;
  margin-top: 0px;
  margin-bottom: 0px;
}

.documentView .sheet .content h6 {
  margin-top: 10px;
}

.documentView .sheet .content table thead th {
  color: #ffffff;
}

.documentView .sheet .content table tbody tr td {
  word-break: "break-all";
}

.documentView .sheet .content {
  overflow: hidden;
  position: absolute;
  margin-right: 15mm;
  margin-bottom: 22mm;
  text-align: justify;
}

.documentView .sheet .content table.smallTable th {
  padding-top: 0;
  padding-bottom: 0;
}

.documentView .sheet .content table.smallTable td {
  padding-top: 0;
  padding-bottom: 0;
}
