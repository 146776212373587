.ao-document {
  font-family: "Times New Roman", Times, serif;
  font-weight: 500;
  font-size: 12px;
}

.ao-document .list-container li {
  padding-bottom: 8px;
}

.ao-document .editable-wrapper {
  width: 140px;
  max-width: 140px;
  height: 20px;
  padding-left: 4px;
  padding-right: 4px;
  display: flex;
}

.ao-document .list-container ol {
  width: 100%;
}

.ao-document .list-item-container {
  width: calc(100% - 80px);
  min-width: 750px;
  max-width: 750px;
  display: flex;
  flex-grow: 1;
  height: 66px;
  flex-wrap: wrap;
  margin-bottom: 12px;
}

.ao-document .list-item-container p {
  all: unset;
  width: max-content;
  max-width: 750px;
  height: 22px;
  padding: 2px 2px 2px 2px !important;
  display: flex;
}

.ao-document .attachment-container {
  width: 50%;
}

.ao-document .attachment-wrapper {
  width: 120px;
  max-width: 120px;
  padding-left: 8px;
}

.ao-document .attachment-container li {
  padding-bottom: 6px;
}

.ao-document .attachment-1 {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 60px;
}

.ao-document .attachment-1-2 {
  margin-top: 40px;
  height: 500px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.ao-document .attachment-1-2 table tr {
  height: 30px;
}

.ao-document .attachment-2 {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ao-document .attachment-3 {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ao-document .attachment-3-table td {
  text-align: center;
}

.ao-document .attachment-3-table thead {
  font-weight: bolder;
}

.ao-document .attachment-3-table td {
  height: 20px;
  text-align: center;
}

.ao-document .footer {
  width: calc(100% - 120px);
  margin-left: 120px;
  margin-right: 60px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  bottom: 60px;
  position: absolute;
}
