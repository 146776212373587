.viewer-pane {
  font-size: 0.9rem;
}

.editor-pane.wmde-markdown-var.w-md-editor.w-md-editor-show-edit {
  height: 100% !important;
}

.editor-pane.wmde-markdown-var.w-md-editor.w-md-editor-show-live {
  height: 100% !important;
}

.editor-pane.wmde-markdown-var.w-md-editor.w-md-editor-show-preview {
  height: 100% !important;
}

.w-md-editor-toolbar button svg {
  width: 16px;
  height: 16px;
}

.w-md-editor-toolbar li button {
  height: 18px;
  padding-left: 8px;
  padding-right: 8px;
}

.w-md-editor-toolbar-divider {
  width: 1.75px;
  height: 16px;
}
