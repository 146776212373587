.itcsc ol li,
.itcsc ul li,
.itcsc ul li p,
.itcsc ol li span {
  padding-left: 10px;
}

.itcsc p {
  padding-bottom: 5px;
  margin-bottom: 0;
}

.itcsc ul {
  margin-bottom: 5px;
}

.itcsc ol li,
.itcsc ul li {
  margin-bottom: 5px;
}


.itcsc>.instructions {
  line-height: 0.9rem;
  font-size: 0.65rem;
}

.page-table {
  width: 100%;
  text-align: center;
}

.page-table table {
  width: 100%;
  height: 1px;
  border: 1px solid black;
  border-collapse: collapse;
}

.page-table th {
  color: black !important;
}

.page-table th.title {
  text-align: center;
  background-color: lightgray;
}

.page-table td.text,
.page-table th.text {
  font-size: 11px;
  text-align: left;
  padding-left: 4px;
  font-weight: normal;
  line-height: normal;
}


.page-table td {
  text-align: left;
  padding: 0px;
}

.page-table td.label {
  text-align: left;
  padding-left: 4px;
  font-weight: bold;
}



.rmf-table {
  width: 100%;
}

.rmf-table table {
  width: 100%;
  border-collapse: collapse;
  border: solid black thin;
}

.rmf-table th,
.rmf-table td {
  border: none;
  padding: 0;
}

.rmf-table td:first-child,
.rmf-table th:first-child {
  border: solid thin;
  padding: 2px;
}

.sig-table {
  width: 100%;
}

.sig-table table {
  width: 100%;
  border-collapse: collapse;
  border: solid black thin;
  border-top: none;
}

.sig-table td {
  padding: 0;
  border-top: none;
}

.sig-table th {
  border-bottom: none;
  border-top: none;
}

.references ul>li {
  margin-bottom: 10px;
}

.references ol {
  counter-reset: list;
}

.offset ol {
  counter-reset: list 15;
}

.offset-26 ol {
  counter-reset: list 26;
}

.references ol>li {
  list-style: none;
  position: relative;
  margin-bottom: 10px;
}

.references ol>li:before {
  counter-increment: list;
  content: counter(list, lower-alpha) ") ";
  position: absolute;
  left: -1.4em;
}