.ctpDocument {
  font-family: "Times New Roman", Times, serif;
  font-weight: 900 !important;
}

.decimalList {
  font-size: 11px;
  height: 100%;
  width: "100%";
  flex-grow: 1;
}

.decimalList > OL {
  counter-reset: item;
  list-style: none;
  padding-right: 80px;
  padding-inline-start: 80px;
}

.decimalList li {
  padding-bottom: 12px;
}

.decimalList > OL > LI::before {
  content: counter(item) ". ";
  counter-increment: item;
}

LI > OL {
  padding-left: 0px;
  counter-reset: subitem;
  list-style: none;
}

LI > OL > LI::before {
  content: counter(item) "." counters(subitem, ".") " ";
  counter-increment: subitem;
}

/* * Page 2*/
.decimalList-page2 {
  font-size: 11px;
  height: 100%;
  width: "100%";
  flex-grow: 1;
}

.decimalList-page2 > OL {
  counter-reset: item 1;
  list-style: none;
  padding-right: 80px;
  padding-inline-start: 80px;
}

.decimalList-page2 li {
  padding-bottom: 12px;
}

.decimalList-page2 > OL > LI::before {
  content: counter(item) ". ";
  counter-increment: item;
}

.decimalList-page2 LI > OL {
  padding-left: 0px;
  counter-reset: subitem;
  list-style: none;
}

.decimalList-page2 LI > OL > LI::before {
  content: counter(item) "." counters(subitem, ".") " ";
  counter-increment: subitem;
}

.fake-list-container {
  width: 100%;
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 12px;
  font-size: 11px;
  font-weight: 900;
}

.fake-list-container p {
  margin-bottom: 6px;
}

.fake-list-container.list-label {
  width: 100%;
}

.fake-list-container.list-label.indent {
}

.fake-list-container .text-content {
  font-weight: 500 !important;
  margin-bottom: 8px;
  flex-wrap: wrap;
}

.fake-list-container .list-label.number {
  width: 40px;
  height: 100%;
}

.list-label-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.list-row-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.fake-list-container .table-container {
  width: 100%;
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 12px;
  font-size: 11px;
  height: 300px;
  /* font-weight: 900; */
}

.table-container {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 8px;
}

.table-container table thead {
  border: 2px solid #000000;
  text-align: center;

  /* padding: 4px; */
}

.table-container table td {
  border: 1px solid #000000;
  padding: 4px;
  word-wrap: normal;
  max-height: 30px;
}

/* .vtm-table {
  height: 30px;
} */

.vtm-table tr {
  /* max-height: 30px; */
  height: 30px;
}

.table-container > .control-table {
  font-size: 11px !important;
}

.control-table td {
  word-spacing: -0.3ch;
}
