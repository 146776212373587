.itcsc {
  font-family: 'Times New Roman', Times, serif;
}
.page-table {
  width: 100%;
  text-align: center;
}

.page-table table {
  width: 100%;
  height: 1px;
  border: 1px solid black;
  border-collapse: collapse;
}

.page-table th {
  color: black !important;
}

.page-table th.title {
  text-align: center;
  background-color: lightgray;
}

.page-table td.text,
.page-table th.text {
  font-size: 11px;
  text-align: left;
  padding-left: 4px;
  font-weight: normal;
  line-height: normal;
}


.page-table td {
  text-align: left;
  padding: 0px;
}

.page-table td.label {
  text-align: left;
  padding-left: 4px;
  font-weight: bold;
}

.page-table td.answer {
  padding: 2px 6px;
}



.rmf-table {
  width: 100%;
}

.rmf-table table {
  width: 100%;
  border-collapse: collapse;
  border: solid black thin;
}

.rmf-table th,
.rmf-table td {
  border: none;
  padding: 0;
}

.rmf-table td:first-child,
.rmf-table th:first-child {
  border: solid thin;
  padding: 2px;
}

.sig-table {
  width: 100%;
}

.sig-table table {
  width: 100%;
  border-collapse: collapse;
  border: solid black thin;
  border-top: none;
}

.sig-table td {
  padding: 0;
  border-top: none;
}

.sig-table th {
  border-bottom: none;
  border-top: none;
}

.offset ol {
  counter-reset: list 15;
}

.offset-26 ol {
  counter-reset: list 26;
}

table.section td {
  padding: 4px;
}

.select-table-row {
  font-size: small;
  padding: 0;
}

.select-table-row td.ant-table-cell {
  padding: 2px !important;
}

table.privacy td {
  text-align: left;
  padding: 4px 20px;
}

table.privacy td+td {
  border-left: 1px solid black;
}

table.privacy_c td {
  padding: 4px;
  vertical-align: baseline;
}

table.privacy_c td .documentSelect {
  width: 100%;
}

table.privacy_c tr.fields td {
  border-top: none;
}

table.privacy_c tr.text td {
  border-bottom: none;
}

.instructions {
  font-family: 'Times New Roman', Times, serif;
  line-height: 0.9rem;
  font-size: 0.65rem;
  padding: 0% 15%;
  display: flex;
  flex-direction: column;
  padding-top: 10%;
}

.instructions>.title {
  font-size: 0.8rem;
  align-self: center;
  font-weight: bold;
  margin-bottom: 15px;
}

ul.slash {
  list-style-type: none;
  padding-left: 0
}

ul.slash li {
  position: relative;
  padding-left: 20px;
}

ul.slash li::before {
  content: "-";
  position: absolute;
  left: 0;
  top: 0;
  color: black;
  font-size: 10px;
}

ul.slash li.no-bullet::before {
  content: "";
}

.references {
  font-family: 'Times New Roman', Times, serif;
  padding: 0% 10%;
  padding-top: 5%;
}

.references .title {
  text-align: center;
  width: 100%;
  font-size: 15px;
}

.references ol {
  counter-reset: list-counter;
  list-style: none;
  padding-left: 0;
}

.references ol>li {
  counter-increment: list-counter;
  margin-bottom: 5px;
  text-align: left;
}

.references ol>li::before {
  content: counter(list-counter, lower-alpha) ") ";
  padding-right: 5px;
}

@counter-style double-lower-alpha {
  system: cyclic;
  symbols: 'aa' 'bb' 'cc' 'dd' 'ee' 'ff' 'gg' 'hh' 'ii' 'jj' 'kk' 'll' 'mm'
    'nn' 'oo' 'pp' 'qq' 'rr' 'ss' 'tt' 'uu' 'vv' 'ww' 'xx' 'yy' 'zz';
  suffix: " ";
  /* space after each symbol before the closing parenthesis in the content */
}

.references ol.double_letters>li {
  padding-left: 5px;
}

.references ol.double_letters>li::before {
  content: counter(list-counter, double-lower-alpha) ") ";
  padding-right: 20px;
}

.references ul {
  padding-left: 0;
}

.references ul>li {
  margin-bottom: 5px;
  text-align: left;
}

.page {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start
}

.page .reset-button {
  margin-top: auto;
  align-self: end;
  padding: 5;

}