.layout-footer {
  text-align: center;
  display: grid;
  place-items: center;
  background-color: white !important;
}

.layout-footer-mobile {
  text-align: center;
  display: grid;
  place-items: center;
  background-color: white !important;
  padding: 12px 25px !important;
}
