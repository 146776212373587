/* Comments Drawer */
.question-comment .ant-comment-inner {
  padding: 0;
}

.answer-comment .ant-comment-inner {
  padding-top: 0;
}

/* Grid Page */
.grid-cell {
  height: 100%;
  vertical-align: top;
}

/* List Page */
/* Linked Questions */
:root {
  --accent-primary: hsl(211, 93%, 16%);
  --accent-dimmed: hsl(210, 10%, 55%);
}

.question-level-tag {
  color: var(--accent-primary);
}

.linked-questions-list {
  position: relative;
  padding-left: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.link-header {
  user-select: none;
  z-index: 5;
  cursor: pointer;
  position: absolute;
  display: flex;
  top: -10px;
  left: 0px;
  padding: 10px;
  border-radius: 50% !important;
  color: var(--accent-primary) !important;
  background-color: white;
  border: 1px solid transparent;
}
.link-header:hover {
  transition: all 0.2s;
  border: 1px solid #40a9ff;
}
.link-header-mini {
  color: white !important;
  background-color: #1890ff;
}
.link-header-mini:hover {
  background-color: #47a6ff;
}

.link-icon {
  display: flex;
  font-size: 1.4rem;
}

.linked-questions-list:before {
  content: "";
  position: absolute;
  top: 0px;
  /* border-left */
  border-left: 2px solid var(--accent-dimmed);
}

.linked-questions-list:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 5px;
  width: 10%;
  /* border-bottom */
  border-bottom: 2px solid var(--accent-dimmed);
}

.link-mini {
  padding-bottom: 25px;
}
.link-mini:before {
  border: none;
}
.minimized-linked-questions-list {
  width: 100%;
  position: absolute;
  top: 0px;
  left: 55px;
  height: auto;
  text-align: left;
}
.minimized-tag {
  color: var(--accent-primary) !important;
}

/* border-bottom */
.minimized-tag:after {
  z-index: 1;
  content: "";
  height: 2px;
  width: 100%;
  position: absolute;
  bottom: 50%;
  margin-left: 25px;
  background-color: var(--accent-dimmed);
}
