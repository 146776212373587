.doc_pcil ul.leaders {
  padding: 0;
  overflow-x: hidden;
  list-style: none;
}
.doc_pcil ul.leaders li {
  padding: 4px;
}
.doc_pcil ul.leaders li:before {
  float: left;
  width: 0;
  white-space: nowrap;
  content: ". . . . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . . . ";
}
.doc_pcil ul.leaders span:first-child {
  padding-right: 0.33em;
  background: white;
}
.doc_pcil ul.leaders span + span {
  float: right;
  padding-left: 0.33em;
  background: white;
}

.doc_pcil ol {
  counter-reset: item;
  list-style-type: none;
  list-style-position: inside;
}
.doc_pcil ol li::before {
  counter-increment: item;
  content: counters(item, ".") ". ";
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
}
.doc_pcil ol li {
  font-size: 12px;
  padding: 20;
}

.doc_pcil table,
.doc_pcil th,
.doc_pcil td {
  border: 1px solid black;
  border-collapse: collapse;
  white-space: nowrap;
}
