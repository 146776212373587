.barChart-skeleton .ant-skeleton-input,
.barChart-skeleton .ant-skeleton {
  width: 100% !important;
}

.barChart-skeleton .ant-space-item:nth-child(1) {
  width: 35% !important;
}

.barChart-skeleton .ant-space-item:nth-child(2) {
  width: 55% !important;
}

.barChart-skeleton .ant-space-item:nth-child(3) {
  width: 75% !important;
}

.barChart-skeleton .ant-space-item:nth-child(4) {
  width: 95% !important;
}
