.workflow-search-view-list {
}

.workflow-search-view-list .ant-pro-table-list-toolbar {
  height: 60px;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  margin-top: -1px;
  z-index: 5;
  background-color: white !important;
  overflow: auto;
  overflow-y: hidden;
}

.workflow-search-view-list
  .ant-list
  .ant-list-vertical
  .ant-list-split
  .ant-pro-list-container {
  position: relative;
}

div.ant-card-body
  > div.ant-collapse.ant-collapse-icon-position-start.categoryCollapse
  > div
  > div.ant-collapse-header.ant-collapse-header-collapsible-only {
  align-items: center;
}

.wf-editor-drawer .ant-drawer-content {
  overflow: hidden !important;
}
