body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
#App {
  text-align: center;
}

.shadowed-static,
.shadowed-static-dark,
.shadowed-w-hover,
.ant-layout-header,
.ant-page-header,
.ant-card,
.ant-alert {
  border-radius: 5px;
}

.shadowed-static,
.shadowed-w-hover {
  box-shadow: 2px 2px 5px rgba(150, 150, 150, 0.2) !important;
  transition: all 0.2s;
}
.shadowed-static-dark {
  box-shadow: 0 1px 4px rgba(150, 150, 150, 0.6);
  transition: all 0.2s;
}

.ant-layout,
.ant-layout-footer {
  background-color: #eaeaea;
}

.ant-layout-header,
.ant-layout-footer {
  border-radius: 0;
}

.ant-list {
  overflow: hidden;
}

.shadowed-w-hover:hover {
  box-shadow: 2px 2px 5px rgba(150, 150, 150, 0.6) !important;
  transition: all 0.2s;
}

.global-loading {
  height: 100vh !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.local-loading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.global-loading .anticon-loading {
  font-size: 32px;
  color: #1890ff;
}

.local-loading .anticon-loading {
  font-size: 24px;
  color: #1890ff;
}
