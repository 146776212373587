.ant-progress-inner,
.ant-progress-bg {
  height: 12px !important;
}

.ant-upload {
  display: flex !important;
  flex-direction: column;
  justify-content: start !important;
}
