.doc_pcilv2 {
  font-family: Times;
}

.doc_pcilv2 ul.leaders {
  padding: 0;
  overflow-x: hidden;
  list-style: none;
}

.doc_pcilv2 ul.leaders li {
  padding: 4px;
}

.doc_pcilv2 ul.leaders li::after {
  float: left;
  width: 0;
  white-space: nowrap;
  content: ". . . . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . "
    ". . . . . . . . . . . . . . . . . . . . ";
}

.doc_pcilv2 ul.leaders li.bold::after {
  font-weight: bolder;
}

.doc_pcilv2 ul.leaders span:first-child {
  padding-right: 0.33em;
  background: white;
}

.doc_pcilv2 ul.leaders span+span {
  float: right;
  padding-left: 0.33em;
  background: white;
}

.doc_pcilv2 table,
.doc_pcilv2 th,
.doc_pcilv2 td {
  border: 1px solid black;
  border-collapse: collapse;
  white-space: nowrap;
}