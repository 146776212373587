.layout-header {
  z-index: 100 !important;
  position: sticky !important;
  top: 0 !important;
  left: 0 !important;
  padding: 0 !important;
  background-color: white !important;
}

.layout-header .header-left {
  text-align: left;
  padding-left: 16px;
}

.layout-header .header-left-mobile {
  padding-left: 8px;
}

.layout-header .header-brand {
  height: 40px;
}

.layout-header .header-title {
  margin: auto 0;
}

.layout-header .header-crumb {
  text-transform: capitalize;
}

.layout-header .header-right {
  text-align: right;
  padding-right: 16px;
}

.layout-header .header-right-mobile {
  padding-right: 8px;
}
